import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://6bed023e172a6ac710d2334dbaad31dc@sentry.auroracore.com.ar/4",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.2,
  replaysOnErrorSampleRate: 1,
});